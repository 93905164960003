import React from 'react'
import { Helmet } from 'react-helmet'
class Leadership extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Leadership | Anun</title>
                    <link rel="canonical" href="http://www.anun.xyz/Leadership" />
                    <meta name="title" content="Leadership | Anun"></meta>
                    <meta name="description" content="Anun leadership biographies include Anun’s executive team responsibilities and experience. Learn more about Anun’s leadership team." />
                    <meta name="keywords" content="leaders anun, anun leadership, anun executive " />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta property="og:url" content="http://www.anun.xyz/Leadership" />
                    
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Leadership | Anun" />
                    <meta property="og:description" content="Anun leadership biographies include Anun’s executive team responsibilities and experience. Learn more about Anun’s leadership team." />
                    <meta property="og:image" content="/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="http://www.anun.xyz/Leadership"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Leadership | Anun" />
                    <meta name="twitter:description" content="Anun leadership biographies include Anun’s executive team responsibilities and experience. Learn more about Anun’s leadership team." />
                    <meta name="twitter:image" content="/assets/images/logo.jpg" />
                    
                </Helmet>
                
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Founders</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <div className="container">
  <div className="row">
 
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader1.jpg" className="card-img-top" alt="masum" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Masum Akando</h5>
          <div className="card-text text-black-50">Co-founder & Chief Executive Officer</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader2.jpg" className="card-img-top" alt="tamal" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Deabashish Sarkar Tamal</h5>
          <div className="card-text text-black-50">Founder & Chief Operating Officer</div>
        </div>
      </div>
    </div>
   
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="assets/images/leader3.jpg" className="card-img-top" alt="hriday" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Hriday Karmaker</h5>
          <div className="card-text text-black-50">Co-founder & Senior Vice President (Internal Affairs) </div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader4.jpg" className="card-img-top" alt="rafi" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Fahamidur Rahaman Rafi</h5>
          <div className="card-text text-black-50">Co-founder & Chief (Corporate Development)</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader5.jpg" className="card-img-top" alt="tanvir" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Tanvir Islam</h5>
          <div className="card-text text-black-50">Co-founder & Chief People Officer</div>
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader6.jpg" className="card-img-top" alt="tuhin" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Rezaul Tanvir Tuhin</h5>
          <div className="card-text text-black-50">Co-founder & Chief Design Officer</div>
        </div>
      </div>
    </div>
    <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Leadership Profiles</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <div className="container">
  <div className="row">
  </div>
   </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader16.jpg" className="card-img-top" alt="dipu" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Omar Faruk Dipu</h5>
          <div className="card-text text-black-50">Chief Growth Officer (Group)</div>
        </div>
      </div>
    </div> 
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/akash.jpg" className="card-img-top" alt="akash" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Iqbal Mahmud Akash</h5>
          <div className="card-text text-black-50">Vice President (Product Design)</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader15.jpg" className="card-img-top" alt="abir" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Abu Adnan Abir</h5>
          <div className="card-text text-black-50">Vice President (Finance & Business Analytics)</div>
        </div>
      </div>
    </div>
    
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader12.jpg" className="card-img-top" alt="harun" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Harun Ur Rashid</h5>
          <div className="card-text text-black-50">Vice President (Machine Learning)</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader13.jpg" className="card-img-top" alt="sajeeb" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Rashedul Kabir Sajeeb</h5>
          <div className="card-text text-black-50">Divisional Director (Rajshahi)</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader14.jpg" className="card-img-top" alt="abir" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Abir Hossain</h5>
          <div className="card-text text-black-50">Divisional Director (Dhaka)</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/tanvir.jpg" className="card-img-top" alt="tanvirul" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Tanvirul Islam</h5>
          <div className="card-text text-black-50">Sr. Software Engineer</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/imam.jpg" className="card-img-top" alt="imam" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Imam Hasan</h5>
          <div className="card-text text-black-50">Sr. Software Engineer</div>
        </div>
      </div>
    </div>
   
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/ib.jpg" className="card-img-top" alt="iqbal" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Iqbal Hossain</h5>
          <div className="card-text text-black-50">Sr. Software Engineer</div>
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="assets/images/ratul.jpg" className="card-img-top" alt="rashidul" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Rashidul Hasan</h5>
          <div className="card-text text-black-50">SQA Lead </div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/leader11.jpg" className="card-img-top" alt="sohan" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Shohanur Rahman</h5>
          <div className="card-text text-black-50">Joint SQA Lead</div>
        </div>
      </div>
    </div>
    
    <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Investors</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <div className="container">
  <div className="row">
 
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/momin.jpg" className="card-img-top" alt="momin" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Md. Momin</h5>
          <div className="card-text text-black-50">Angel Investor</div>
        </div>
      </div>
    </div>
   </div>
   </div>
    <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Executive Profiles</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <div className="container">
  <div className="row">
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/afifa.jpg" className="card-img-top" alt="afifa" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Afifa Hossain</h5>
          <div className="card-text text-black-50">SQA Engineer</div>
        </div>
      </div>
    </div>

    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/mou.jpg" className="card-img-top" alt="mou" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Mou Afroz</h5>
          <div className="card-text text-black-50">SQA Engineer</div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/layla.jpg" className="card-img-top" alt="layla" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">Kamrun Layla</h5>
          <div className="card-text text-black-50">SQA Engineer</div>
        </div>
      </div>
    </div> 
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-0 shadow">
        <img src="/assets/images/km.jpg" className="card-img-top" alt="km" />
        <div className="card-body text-center">
          <h5 className="card-title mb-0">K. M. Habibullah</h5>
          <div className="card-text text-black-50">Graphics Designer</div>
        </div>
      </div>
    </div>
    
    </div>
   </div>
  </div>
  </div>
  
   </div>
        )
    }


}

export default Leadership