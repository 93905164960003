import React from 'react'
import { Helmet } from 'react-helmet'
class Livechat extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Live Chat | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Livechat" />
                    <meta name="title" content="Live Chat | Anun"></meta>
                    <meta name="description" content="Livechat is for best customer service experience." />
                    <meta name="keywords" content="anun help, anun helpline, anun live chat" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:url" content="https://www.anun.xyz/Livechat" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Live Chat | Anun" />
                    <meta property="og:description" content="Livechat is for best customer service experience." />
                    <meta property="og:image" content="/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Livechat"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Live Chat | Anun" />
                    <meta name="twitter:description" content="Livechat is for best customer service experience." />
                    <meta name="twitter:image" content="/assets/images/logo.jpg" />
                    
                </Helmet>
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Live Chat</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
                <section className="join-scetion ptb100">
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-12">
        <p>
          <iframe title="anun" id="livechat" onload="setTimeout(function(){jQuery('.spinner').hide()}, 2000)" src="https://tawk.to/chat/5ed9d7ad9e5f6944228fedbd/1eb0n9piq" style={{width: '100%', height: 610, border: 'none'}} /></p>
      </div>
    </div>
  </div>
</section>

            </div>

        )
    }


}

export default Livechat