import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, withRouter } from "react-router-dom";
import Footer from './Component/Footer';
import Header from './Component/Header';
import Brand from './pages/Brand';
import Careers from './pages/Careers';
import Events from './pages/Events';
import Faq from './pages/Faq';
import Guideline from './pages/Guideline';
import Home from './pages/Home';
import Investors from './pages/Investors';
import Leadership from './pages/Leadership';
import Livechat from './pages/Livechat';
import Media from './pages/Media';
import NotFound from './pages/NotFound';
import Partner from './pages/Partner';
import Privacy from './pages/Privacy';
import Story from './pages/Story';
import Terms from './pages/Terms';
import Contact from './pages/Contact';








function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)






function App() {
  

  

   
  
  return (
    <div>
      <Router>
      <ScrollToTop>
        <Header />
        
        <Switch>
        
                
                <Route exact path='/' component={Home} />
                <Route path='/Brand' component={Brand} />
                <Route path='/Careers' component={Careers} />
               
                <Route path='/Events' component={Events} />
                <Route path='/Guideline' component={Guideline} />
                <Route path='/Investors' component={Investors} />
                <Route path='/Leadership' component={Leadership} />
                <Route path='/Livechat' component={Livechat} />
                <Route path='/Media' component={Media} />
                <Route path='/Partner' component={Partner} />
                <Route path='/Privacy' component={Privacy} />
                <Route path='/Story' component={Story} />
                <Route path='/Terms' component={Terms} />
                <Route path='/Faq' component={Faq} />
                <Route path='/Contact' component={Contact} />
           
                <Route component={NotFound} />
            
               
               
                
         
    


        </Switch>

        <Footer />
        </ScrollToTop>
      </Router>


    </div>

  );
}


export default App;
