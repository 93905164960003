import React from 'react'
import { Helmet } from 'react-helmet'
class Privacy extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Privacy Policy | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Privacy" />
                    <meta name="title" content="Privacy Policy | Anun"></meta>
                    <meta name="description" content="This Privacy Policy clarifies how we gather, utilize and (under specific conditions) uncover your own data." />
                    <meta name="keywords" content="anun privacy policy" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:url" content="https://www.anun.xyz/Privacy" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Privacy Policy | Anun" />
                    <meta property="og:description" content="This Privacy Policy clarifies how we gather, utilize and (under specific conditions) uncover your own data." />
                    <meta property="og:image" content="/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Privacy"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Privacy Policy | Anun" />
                    <meta name="twitter:description" content="This Privacy Policy clarifies how we gather, utilize and (under specific conditions) uncover your own data." />
                    <meta name="twitter:image" content="/assets/images/logo.jpg" />
                    
                </Helmet>
                
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Privacy Policy</h2>
            <p align="justify">Last Update on 28 December, 2022</p>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <section className="ptb100 pt60">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="job-details-info">
          <p align="justify">Welcome to <strong>anun.xyz</strong>; a site conducted and operated under Anun Limited. We respect your privacy and want to protect your personal information. To learn more, please read this Privacy Policy.</p>
<p align="justify">This Privacy Policy clarifies how we gather, utilize and (under specific conditions) uncover your data. This Privacy Policy additionally clarifies the means we have taken to secure your data. At long last, this Privacy Policy clarifies your alternatives concerning the accumulation, utilize and divulgence of your data. By going to the site specifically or through another site, you acknowledge the practices depicted in this Policy.</p>
<p align="justify">Information security involves trust and your protection is essential to us. We should, along these lines, just utilize your name and other data which identifies with you in the way set out in this Privacy Policy. We will just gather data where it is fundamental for us to do as such and we will just gather data on the off chance that it is significant to our dealings with you.</p>
<p align="justify">We will just keep your data for whatever length of time that we are either required to by law or as is pertinent for the reasons for which it was gathered.</p>
<p align="justify">You can visit the Site and peruse without providing individual points of interest. Amid your visit to the Site you stay mysterious and at no time would we be able to distinguish you unless you have a record on the Site and sign on with your client name and secret word.</p>
<h4>What kind of information do we collect?</h4>
<p align="justify">We may gather different snippets of data if you look to submit a request for an item with us on the site.</p>


<p align="justify"><strong>User profile information:</strong> We collect data when users create or update their Anun accounts. This may include their name, email, phone number, password, address, profile picture, payment or banking information (including related payment verification information), driver’s license and other government issued documents (which may include identification numbers as well as birth date, gender, and photo). This also includes vehicle or insurance information of drivers and delivery persons, emergency contact information, user settings, and evidence of health or fitness to provide services using Anun apps. </p>
<p align="justify"><strong>Background check information (riders and merchants): </strong> This includes information submitted during the riders /merchant's application process, such as rider history or criminal record (where permitted by law), license status, known aliases, prior addresses, and right to work. This information may be collected by an authorized vendor on Anun’s behalf.</p>
<p align="justify"><strong>Identity verification photos:</strong>This includes photos of users (such as, selfies) and/or their government issued identifications (such as, driver’s license or passports). Such photos may be used to verify a user’s identity, such as through facial verification technologies. </p>



<h4>Data created during use of our services.</h4>

<p align="justify"><strong>Partnered Businesses We Do Not Control:</strong>We work intimately with associated organizations. Now and again, for example, Marketplace merchants, these organizations work stores at anun.xyz or pitch offerings to you at anun.xyz. In different cases, we work stores, give administrations, or offer product offerings mutually with these organizations. You can tell when an outsider is engaged with your exchanges, and we share client data identified with those exchanges with that outsider.</p>
<p align="justify"><strong>Outsider Service Providers:</strong> We utilize different organizations and people to perform works for our benefit. Illustrations incorporate satisfying requests, conveying bundles, sending postal mail and email, expelling dreary data from client records, investigating information, giving promoting help, giving list items and connections (counting paid postings and connections), handling Visa instalments, and giving client benefit. They approach individual data expected to play out their capacities, yet may not utilize it for different purposes.</p>
<p align="justify"><strong>Limited-time Offers:</strong> Sometimes we send offers to choose gatherings of anun.xyz clients in the interest of different organizations. When we do this, we don't give that business your name and address.</p>
<p align="justify"><strong>Business Transfers:</strong>As we keep on developing our business, we may offer or purchase stores, auxiliaries, or speciality units. In such exchanges, client data for the most part is one of the exchanged business resources yet stays subject to the guarantees made in any prior Privacy Notice (unless the client assents generally). Additionally, in the improbable occasion that anun.xyz, or generously the greater part of its advantages are gained, client data will be one of the exchanged resources.</p>
<p align="justify"><strong>Insurance of anun.xyz and Others: </strong> We discharge account and other individual data when we trust discharge is fitting to consent to the law; uphold or apply our Conditions of Use and different assertions; or ensure the rights, property, or wellbeing of anun.xyz, our clients, or others. This incorporates trading data with different organizations and associations for extortion assurance and credit hazard decrease. Be that as it may, this does exclude offering, leasing, sharing, or generally revealing identifiable data from clients for business purposes infringing upon the duties put forward in this Privacy policy.
</p>
<p align="justify"><strong>With Your Consent:</strong> Other than as set out above, you will get see when data about you may go to outsiders, and you will have a chance to pick not to share the data.</p>
            
          </div>
        </div>
      </div>
    </div></section>

            </div>

        )
    }


}

export default Privacy