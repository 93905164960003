import React from 'react'
import { Helmet } from 'react-helmet'
class Events extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Events | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Events" />
                    <meta name="title" content="Events | Anun"></meta>
                    <meta name="description" content="Watch the latest Anun keynote stream, and check out the archive of special event announcements for our products and services." />
                    <meta name="keywords" content="anun events, anun announcement" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                
                    
                    <meta property="og:url" content="https://www.anun.xyz/Events" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Events | Anun" />
                    <meta property="og:description" content="Watch the latest Anun keynote stream, and check out the archive of special event announcements for our products and services." />
                    <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Events"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Events | Anun" />
                    <meta name="twitter:description" content="Watch the latest Anun keynote stream, and check out the archive of special event announcements for our products and services." />
                    <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
                    
                </Helmet>
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Anun Events</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
                <div className="video-container">
    <iframe title="anun" src="https://www.youtube.com/embed/ZtuYHWtJT_8" width={1280} height={720} style={{border: 'none', overflow: 'hidden'}} scrolling="no" frameBorder={0} allowTransparency="true" allowFullScreen="true"> </iframe>
  </div>  
  <style dangerouslySetInnerHTML={{__html: "\n     .video-container {\n    overflow: hidden;\n    position: relative;\n    width:100%;\n}\n\n.video-container::after {\n    padding-top: 55.75%;\n    display: block;\n    content: '';\n}\n\n.video-container iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n " }} />


            </div>

        )
    }


}

export default Events