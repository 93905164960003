import { BackTop } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';






ReactDOM.render(




  <React.StrictMode>
   <App />
    
    
    <BackTop />
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
