import React from "react";
import { Helmet } from "react-helmet";

class Faq extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ | Anun</title>
          <link rel="canonical" href="https://www.anun.xyz/Faq" />
          <meta name="title" content="FAQ | Anun"></meta>
          <meta name="description" content="We've answered all of your frequently asked questions." />
          <meta name="keywords" content="anun,anun faq" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                        <meta property="og:url" content="https://www.anun.xyz/Faq" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="FAQ | Anun" />
                        <meta property="og:description" content="We've answered all of your frequently asked questions." />
                        <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

                        <meta property="twitter:url" content="https://www.anun.xyz/Faq"></meta>
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content="FAQ | Anun" />
                        <meta name="twitter:description" content="We've answered all of your frequently asked questions." />
                        <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
        </Helmet>
        <section className="page-title pt115">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="section-title alt-left">
                  <h2>FAQ</h2>
                  <p>All frequently asked questions & answers</p>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="faq">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
               
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          How do I create an account?
</button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>To create an Anun account you need a valid email address and mobile number.
                        Download the app for your Android device and sign up with your mobile
number.</p>
                        <p>To make sure it’s your real number, we’ll send you a verification code via SMS
which you need to enter to sign up.</p>
                        <p>Once your number is verified, you’re ready to use Anun!</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          How can I pay?
</button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>You can pay directly by Nagad or recharging through your Anun Pay wallet by bkash, rocket.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How do I become a Partner?
</button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Becoming a partner is a simple process. First you will need to fill up a form in https://www.anun.xyz/partner .
                        After reviewing your application, we will reach you for negotiation and a training session.
Once the training is complete, you can start selling with us. </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                          How do I become a Rider?
</button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>
                          Becoming a rider is a simple process. First you will need to
                          register in https://rider.anun.xyz by completing the form available on the website.
                          After reviewing your application, we will invite you for a training session.
                          Once the training is complete, you can start riding with us.
</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                          Where can I get more information, support or make a claim?
</button>
                      </h2>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>We do our best to provide you with all the help you may need in our FAQs. If you
need to get in touch with us, you can go to the app Menu &gt; Live Chat.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* / FAQ */}
        
      </div>
    );
  }
}

export default Faq;
