import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
class NotFound extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
            
                    <meta charSet="utf-8" />
                    <title>404 Error</title>
                    <link rel="canonical" href="https://www.anun.xyz/NotFound" />
                    
                    <meta name="title" content="404 Error"></meta>
                    <meta name="description" content="404 Error" />
                    <meta name="keywords" content="404 Error" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                
                    
                    <meta property="og:url" content="https://www.anun.xyz/NotFound" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="404 Error" />
                    <meta property="og:description" content="404 Error" />
                    <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/NotFound"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="404 Error" />
                    <meta name="twitter:description" content="404 Error" />
                    <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
                    
                </Helmet>
               


  
  
  <div id="main">
    	<div class="fof">
           
        		<h1>404 Not found</h1>

    	
  <p> We couldn't find what you were looking for. </p>
  
    <Link to="/" className="btn btn-success">
      Go Back To Home 
    </Link>  
     
    
</div>
</div>
</div>
  
 


            

        )
    }


}

export default NotFound