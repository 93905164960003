import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import React from 'react';
import { Helmet } from 'react-helmet';
class Story extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Our Story | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Story" />
                    <meta name="title" content="Our Story | Anun"></meta>
                    <meta name="description" content="Learn about Anun’s mission to serve Bangladesh." />
                    <meta name="keywords" content="about anun" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:url" content="https://www.anun.xyz/Story" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Our Story | Anun" />
                    <meta property="og:description" content="Learn about Anun’s mission to serve Bangladesh." />
                    <meta property="og:image" content="/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Story"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Our Story | Anun" />
                    <meta name="twitter:description" content="Learn about Anun’s mission to serve Bangladesh." />
                    <meta name="twitter:image" content="/assets/images/logo.jpg" />
                    
                </Helmet>
                
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Our Story</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <React.Fragment>
      <Timeline align="alternate">
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">2019</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>Start as Food Delivery App</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">2020</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>Introduced as Super App</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">2021</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>Introduced as Social Commerce App</Typography>
          </TimelineContent>
        </TimelineItem>
        
      </Timeline>
    </React.Fragment>
  <section className="ptb100 pt60">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="job-details-info">
            
            <p align="justify">Our story begins in 2019 when we started as a food delivery app. We were just like any other food delivery service, providing people with a convenient way to order food from their favorite restaurants and have it delivered straight to their doorsteps.

But we didn't want to stop there. We wanted to be more than just a food delivery app. We wanted to be a super app, a one-stop-solution for all the needs of our customers.

So in 2020, we introduced our new super app, which included not just food delivery, but also other services such as grocery delivery, medicine delivery etc.

But we didn't stop there either. We wanted to continue to innovate and offer even more value to our customers. So in 2021, we became a social commerce app, and that's when we launched Anun.

Anun is a Bangladeshi third-party social commerce platform that provides group buying offers at reduced prices. With Anun, shoppers can choose to buy products individually or initiate or join a team purchase.

When a shopper places an order, they can launch the entire interaction by placing an initial installment. They can then share relevant product information with their friends on various social media platforms.

To successfully make a group purchase, there need to be enough friends showing their interest in buying the product. If there aren't enough interested buyers, the platform will refund the initial payment or mark it as an open team if the order cannot proceed further.

Alternatively, the shopper can send out a price reduction post to their circle of friends on social media. The shopper and their friends can enjoy a discount if there are more potential buyers in the pool, and eventually, the shopper may get the product for free if the pool of buyers is big enough.

Additionally, users can buy discount vouchers for local shops to enjoy discounts on physical shopping. This feature offers more excitement and huge savings to our customers.

Today, we're proud to offer our customers a full suite of services, from food delivery to social commerce. And we'll continue to innovate and find new ways to provide value to our customers.</p>
            
          </div>
        </div>
      </div>
    </div>
  </section>
  

            </div>

        )
    }


}

export default Story