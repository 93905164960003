import React from 'react'
import Announcement from 'react-announcement'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Logo from './App icon.svg'



class Home extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anun | Social Quick Commerce</title>
          <link rel="canonical" href="https://www.anun.xyz" />

          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="title" content="Anun | Social Quick Commerce"></meta>
          <meta name="description" content="Anun is the first Bangladeshi Team Purchasing or Group Buying Social Commerce App. " />
          <meta name="keywords" content="anun, anun app , super app, team purchase bd, social commerce bangladesh, bangladeshi group buying app , saveings app" />
        

          <meta itemProp="name" content="Anun | Social Quick Commerce" />
          <meta itemProp="description" content="Anun is the first Bangladeshi Team Purchasing or Group Buying Social Commerce App." />
          <meta itemProp="image" content="https://www.anun.xyz/assets/images/logo.jpg" />


          <meta property="og:url" content="https://www.anun.xyz" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Anun | Social Quick Commerce" />
          <meta property="og:description" content="Anun is the first Bangladeshi Team Purchasing or Group Buying Social Commerce App." />
          <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

          <meta property="twitter:url" content="https://www.anun.xyz"></meta>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Anun | Social Quick Commerce" />
          <meta name="twitter:description" content="Anun is the first Bangladeshi Team Purchasing or Group Buying Social Commerce App." />
          <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
         
        </Helmet>
       
        <div id="hero-area" className="hero-area-bg">
          
             
                <div className="contents">
                 <center> <h1 className="header-title"><span style={{ color: 'black' }}>Share &amp; Purchase</span> <br/> <span style={{ color: '#01af4d' }}>Together </span></h1>
                  <h3>#1 Team Purchasing Social Quick Commerce app</h3> 
                  
                  <div className="header-button">
                
                                                        
                                                        <li className="list-inline-item">
                                                            <a href="https://play.google.com/store/apps/details?id=app.anun.xyz&hl=en" target="_parent"><img src="assets/images/play-store.png" height={50} weidth={38} alt="play store" /></a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a href="https://play.google.com/store/apps/details?id=app.anun.xyz&hl=en" target="_parent"><img src="assets/images/app-galary.png" height={50} weidth={38} alt="play store" /></a>
                                                        </li>
                                                                 
                  </div>
                  </center>
                  
                </div>
             
              
           
        </div>
    
        <Announcement title="Download New anun app" subtitle="Download the new anun app & save more by team purchase." link="https://play.google.com/store/apps/details?id=app.anun.xyz&hl=en&gl=US" imageSource={Logo} secondsBeforeBannerShows={7} closeIconSize={24} />
        <section class="fun-facts-section pt100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="fun-facts-inner ext">
                        
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="fun-facts-info">
                                        <h2>3</h2>
                                        <p>Countries</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="fun-facts-info">
                                        <h2>9000<span>+</span></h2>
                                        <p>Total Users</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="fun-facts-info">
                                        <h2>12<span>+</span></h2>
                                        <p>Cities</p>
                                    </div>
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
             </section>
     
        <section id="services">
        
          <div className="container">
        
            <h2>Delivery within 15 Minutes + Team Purchase</h2>
           
            <div className="row">
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Khabar.svg" alt="anun food delivery " />
Khabar
</div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Medicine.svg" alt="anun Medicine delivery " />
Medicine
</div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Sodai Express.svg" alt="anun grocery delivery" />
Sodai
</div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Fresh.svg" alt="anun Fresh food delivery" />
Fresh
</div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Shop.svg" alt="anun hyperlocal e-commerce" />
Market
</div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Parcel.svg" alt="anun Parcel" />
Parcel
</div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Krishi Ghor.svg" alt=" anun agriculture service" />
Krishi Ghor
</div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="service-item">
                  <img src="/assets/images/Voucher.svg" alt="anun voucher" />
Voucher
</div>
              </div>
            </div>    
              
          </div>
        </section>
        

        <section id="features">
          <div className="container">
            <h2>Why Anun?</h2>
            <div className="swiper-container d-lg-none">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="feature-item">
                    <div className="feature-item-image" style={{ backgroundImage: 'url("/assets/images/feature-1.svg")' }} />
                    <div className="feature-item-content" style={{ backgroundColor: '#e3f3f0' }}>
                      <h4>Shop and Save Together</h4>
                      <p>Anun makes team purchases easy and convenient. Choose from thousands of products at discounted prices.</p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="feature-item">
                    <div className="feature-item-image" style={{ backgroundImage: 'url("/assets/images/feature-2.svg")' }} />
                    <div className="feature-item-content" style={{ backgroundColor: '#ece9f4' }}>
                      <h4>Get the Best Deals</h4>
                      <p>We have created a new platform so that everyone can get benefits from it. From users to partners, everyone can be benefited.</p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="feature-item">
                    <div className="feature-item-image" style={{ backgroundImage: 'url("/assets/images/feature-3.svg")' }} />
                    <div className="feature-item-content" style={{ backgroundColor: '#fbf2d1' }}>
                      <h4> Fast, Affordable, and Convenient</h4>
                      <p> Say goodbye to long delivery times.with lightning-fast delivery straight to your door. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item" id="feature-1">
                    <div className="feature-item-image" style={{ backgroundImage: 'url("/assets/images/feature-1.svg")' }} />
                    <div className="feature-item-content" style={{ backgroundColor: '#e3f3f0' }}>
                      <h4>Shop and Save Together</h4>
                      <p>Anun makes team purchases easy and convenient by offering a wide range of products at discounted prices. Choose from thousands of items to find exactly what you need.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item" id="feature-2">
                    <div className="feature-item-content" style={{ backgroundColor: '#ece9f4' }}>
                      <h4>Get the Best Deals</h4>
                      <p>Anun's team purchase option allows you and your friends to purchase products at a discounted price, making shopping easier than ever before.</p>
                    </div>
                    <div className="feature-item-image" style={{ backgroundImage: 'url("/assets/images/feature-2.svg")' }} />
                  </div>
                  <div className="feature-item" id="feature-3">
                    <div className="feature-item-image" style={{ backgroundImage: 'url("/assets/images/feature-3.svg")' }} />
                    <div className="feature-item-content" style={{ backgroundColor: '#fbf2d1' }}>
                      <h4> Fast, Affordable, and Convenient</h4>
                      <p> Say goodbye to long delivery times with lightning-fast delivery straight to your door that is both affordable and convenient. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section id="new-features">
           <div className="new-feature" id="new-feature-1">
            <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-1.svg")' }} />
            <div className="container">
              <div className="col-lg-5 offset-lg-7">
                <h2>Innovation is everywhere</h2>
                <p align="justify">Conventional e-commerce to <b>next-generation new e-commerce platform </b> is a long journey. From seed to the dining table, we see there is an incredible chance to develop. So we continue trying to innovate new ideas which can ready to make our life easy.</p>
              </div>
            </div>
          </div>
          
          <div className="new-feature new-feature-reverse" id="new-feature-2">
            <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-2.svg")' }} />
            <div className="container">
              <div className="col-lg-5">
                <h2>Targeted Consumers</h2>
                <p align="justify">Our targeted consumers are price-conscious (as opposed to brand-conscious) buyers in rural cities – an underserved market. We enable these buyers to <b>obtain deep discounts </b>by getting their friends to participate in group buys of items ranging from food to property.</p>
              </div>
            </div>
          </div>
          <div className="new-feature" id="new-feature-3">
            <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-3.svg")' }} />
            <div className="container">
              <div className="col-lg-5 offset-lg-7">
                <h2>Consumer's Group Buying Trends</h2>
                <p align="justify">Consumers are at the heart of everything we do at Anun. We drive re-engagement and user retention by some of the latest consumer trends. Be a detective, find the <b> lowest group buying deals</b> and shopping at Anun</p>
              </div>
            </div>
          </div>
          <div className="new-feature new-feature-reverse" id="new-feature-4">
            <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-4.svg")' }} />
            <div className="container">
              <div className="col-lg-5 ">
                <h2>New team purchase experience</h2>
                <p align="justify">Would-be shoppers have to form a team, and the app shows <b>the discount increasing</b> with each additional shoppers added.  This creates urgency and excitement.  For shoppers who love a bargain, it’s an irresistible experience.</p>
              </div>
            </div>
          </div>
          <div className="new-feature" id="new-feature-5">
            <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-5.svg")' }} />
            <div className="container">
              <div className="col-lg-5 offset-lg-7">
                <h2>Logistics Infrastructure</h2>
                <p align="justify">Logistics is a vital part of the e-commerce ecosystem and Anun is investing to develop more technology solutions with logistics providers <b>to create more value </b> for our users and merchants. Also, we've our own logistics solutions.</p>
              </div>
            </div>
          </div>
          <div className="new-feature new-feature-reverse" id="new-feature-6">
            <div className="new-feature-image" style={{ backgroundImage: 'url("/assets/images/new-feature-6.svg")' }} />
            <div className="container">
              <div className="col-lg-5 ">
                <h2>Manufacturing Model</h2>
                <p align="justify">Anun is adopting a new strategy <b>to demand and supply</b> - by enabling manufacturers with bits of knowledge into consumer preferences, supply can be optimized to fulfill user needs, limiting waste and permitting users to enjoy greater savings.</p>

              </div>
            </div>
          </div>
        </section>
        <section className="pt-5 pb-5 newsheadlines-section awards-section bg-light animated fadeIn">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6">
        <div className="section-title text-center mb-4 wow fadeInUp" data-wow-duration="2s">
          <h2>Our Profiles</h2>
        </div>
      </div>
    </div>
    <div className="newsheadlines-wrapper">
      <div className="row">
        <div className="col-md-4">
          <div className="card mb-4 wow animated fadeIn" data-wow-duration=".7s">
            <a className="card-body nav-link p-0 d-flex justify-content-center align-items-center"
              href="https://angel.co/company/anun"
              target="_blank"
              rel="noopener noreferrer">
              <img className="img-fluid" src="/assets/images/ag.png" alt="AngelList" />
            </a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4 wow animated fadeIn" data-wow-duration=".7s" data-wow-delay=".2s">
            <a className="card-body nav-link p-0 d-flex justify-content-center align-items-center"
              href="https://www.crunchbase.com/organization/anun"
              target="_blank"
              rel="noopener noreferrer">
              <img className="img-fluid" src="/assets/images/cr.png" alt="Crunchbase" />
            </a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-4 wow animated fadeIn" data-wow-duration=".7s" data-wow-delay=".4s">
            <a className="card-body nav-link p-0 d-flex justify-content-center align-items-center"
              href="https://www.f6s.com/anun"
              target="_blank"
              rel="noopener noreferrer">
              <img className="img-fluid" src="/assets/images/fs.png" alt="F6S" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  <section id="become">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="become-card" style={{ backgroundImage: 'url("/assets/images/become-1.jpg")' }}>
                  <h2>Ride with us</h2>
                  <p>Join our legendary rider team today and start earning money.</p>
                  <Link to={{ pathname: "https://rider.anun.xyz" }} className="button" target="_parent">Register Now </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="become-card" style={{ backgroundImage: 'url("/assets/images/become-2.jpg")' }}>
                  <h2>Launch your Business</h2>
                  <p>Partner with fastest growing startup and grow your business.</p>
                  <Link to="/Partner" className="button" >Become a Partner</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="becomes-bg" />
          
        </section>
        <marquee class="GeneratedMarquee" direction="left" scrollamount="8" behavior="scroll">
  Delivery within 15 Minutes is our commitment
</marquee>
       
 
        <section id="cities">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <h2>Here’s where we deliver </h2>
                <p>Find us in your cities</p>
                <Link to="/" className="button-link" id="show-cities">View All Cities</Link>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <div id="cities-images">
                  <div className="city-image-item" id="city-image-1">
                    <span className="city-image-item-point" />
                    <div className="city-image-item-wrapper">
                      <figure style={{ backgroundImage: 'url("/assets/images/city-image-1.jpg")' }} />
                    </div>
                  </div>
                  <div className="city-image-item" id="city-image-2">
                    <span className="city-image-item-point" />
                    <div className="city-image-item-wrapper">
                      <figure style={{ backgroundImage: 'url("/assets/images/city-image-2.jpg")' }} />
                    </div>
                  </div>
                  <div className="city-image-item" id="city-image-3">
                    <span className="city-image-item-point" />
                    <div className="city-image-item-wrapper">
                      <figure style={{ backgroundImage: 'url("/assets/images/city-image-3.jpg")' }} />
                    </div>
                  </div>
                  <div className="city-image-item" id="city-image-4">
                    <span className="city-image-item-point" />
                    <div className="city-image-item-wrapper">
                      <figure style={{ backgroundImage: 'url("/assets/images/city-image-4.jpg")' }} />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt150 pb150 newsheadlines-section">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div
          className="section-title text-center wow fadeInUp"
          data-wow-duration=".7s"
        >
          <h2 className="mb20">Featured By</h2>
        </div>
      </div>
    </div>
    <div className="newsheadlines-wrapper">
      <ul className="nav nav-tabs" id="newsheadlinesTab" role="tablist">
        <li className="nav-item">
          <a
            aria-selected="true"
            className="nav-link active"
            id="newsheadline-0-tab"
            data-toggle="tab"
            href="#newsheadline-0"
            role="tab"
            aria-controls="newsheadline-0"
          >
            <img
              width={172}
              height={85.5}
              src="https://www.jugantor.com/web-assets/img/jugantor-logo.svg"
              alt='jugantor'
            />
          </a>
        </li>
        <li className="nav-item">
          <a
            aria-selected="false"
            className="nav-link"
            id="newsheadline-1-tab"
            data-toggle="tab"
            href="#newsheadline-1"
            role="tab"
            aria-controls="newsheadline-1"
          >
            <img
              width={152}
              height={59}
              src="https://cdn.risingbd.com/media/common/risingbd-logo-small-sm.png"
              alt="risingbd"
            />
          </a>
        </li>
        <li className="nav-item">
          <a
            aria-selected="false"
            className="nav-link"
            id="newsheadline-2-tab"
            data-toggle="tab"
            href="#newsheadline-2"
            role="tab"
            aria-controls="newsheadline-2"
          >
            <img
               width={152}
               height={50}
              src="http://www.bbarta24.net/templates/bbarta/images/sticky_logo.jpg"
              alt="bbarta"
            />
          </a>
        </li>
        <li className="nav-item">
          <a
            aria-selected="false"
            className="nav-link"
            id="newsheadline-3-tab"
            data-toggle="tab"
            href="#newsheadline-3"
            role="tab"
            aria-controls="newsheadline-3"
          >
            <img
               width={146}
               height={70}
              src="https://digibanglatech.news/wp-content/uploads/2021/09/logo-digi-high.png"
              alt="digibangla"
            />
          </a>
        </li>
      </ul>
      <div className="tab-content" id="newsheadlinesTabContent">
        <div
          className="tab-pane fade newsheadline-content show active"
          id="newsheadline-0"
          role="tabpanel"
          href="#newsheadline-0"
          aria-labelledby="newsheadline-0-tab"
        >
          <p className="title">
          On demand super app Anun
          </p>
          <p className="excerpt">
          The on-demand app 'Anun' has been created with the initiative of 6 young people of the country who have been created to meet the various needs of daily life.
          </p>
          <a
            
            className="btn-trans button"
            href="https://www.jugantor.com/todays-paper/it-world/322431/%E0%A6%85%E0%A6%A8%E0%A6%A1%E0%A6%BF%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%A1-%E0%A6%B8%E0%A7%81%E0%A6%AA%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%AA-%E0%A6%86%E0%A6%A8%E0%A7%81%E0%A6%A8"
          >
            View Article <i className="btn-arrow-right" />
          </a>
        </div>
        <div
          className="tab-pane fade newsheadline-content"
          id="newsheadline-1"
          role="tabpanel"
          href="#newsheadline-1"
          aria-labelledby="newsheadline-1-tab"
        >
          <p className="title">
          Launch of the on-demand super app 'Anun'
          </p>
          <p className="excerpt">
          Daffodil International University's 6 young people's new initiative on demand super app 'Anun'. The app was inaugurated by Syed Mojibul Haque, Director (Additional Secretary), Idea Project, Department of Information and Communication Technology, through an online product event in a video conference on Wednesday night.
          </p>
          <a
            
            className="btn-trans button"
            href="https://www.risingbd.com/scienceand-technology-news/359510"
          >
            View Article <i className="btn-arrow-right" />
          </a>
        </div>
        <div
          className="tab-pane fade newsheadline-content"
          id="newsheadline-2"
          role="tabpanel"
          href="#newsheadline-2"
          aria-labelledby="newsheadline-2-tab"
        >
          <p className="title">The dream journey of six young people</p>
          <p className="excerpt">
          He wanted to do many things in life. But he did not remain steadfast in any desire. Repeatedly switched. At school, when teachers asked him what he wanted to be when he grew up
          </p>
          <a
            
            className="btn-trans button"
            href="http://www.bbarta24.net/apon-aloy/124545"
          >
            View Article <i className="btn-arrow-right" />
          </a>
        </div>
        <div
          className="tab-pane fade newsheadline-content"
          id="newsheadline-3"
          role="tabpanel"
          href="#newsheadline-3"
          aria-labelledby="newsheadline-3-tab"
        >
          <p className="title">
          On demand super app 'Anun' released
          </p>
          <p className="excerpt">
          Not the capital, the app has been brought for the people of 30 cities and rural areas outside Dhaka including Tangail, Ashulia, Savar, Joypurhat, Mirzapur, Manikganj and Keraniganj.
          </p>
          <a
            
            className="btn-trans button"
            href="https://digibanglatech.news/apps-main/30521/"
          >
            View Article <i className="btn-arrow-right" />
          </a>
        </div>
      </div>
      <br/><br/><br/>
    </div>
  </div>
</section>

        <section id="faq">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2>Frequently Asked Questions</h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          How do I create an account?
</button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>To create an Anun account you need a valid email address and mobile number.
                        Download the app for your Android device and sign up with your mobile
number.</p>
                        <p>To make sure it’s your real number, we’ll send you a OTP verification code via SMS
which you need to enter to sign up.</p>
                        <p>Once your number is verified, you’re ready to use Anun Social Commerce App!</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          How can I pay?
</button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>You can pay directly by Nagad, Gift Card or recharging through your Anun Pay wallet by bkash, rocket, Nagad.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How can I get hourly check-ins reward?
</button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>To get hourly check-ins reward is almost easy. Open Anun app in every hour and go to the Check-In section. After that every process is super easy and interactive. </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        Is it true price chopping to zero and opprtunity to get free product?
</button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>
                        Yes, dear, it is absolutely true. Anun gives you that opportunity.</p> <p>However long you get a large enough team it's even possible to get a particular item free of charge. In the event that a shopper goes to the price chop section in the Anun app, they can choose items that they need to get free of charge, which will set of the 24-hour clock. </p> <p>Inside this time period, the shopper should then share the link or team code with as many friends as possible. The way it works is that every shopper who taps on the link or code, join, purchase the individual who began the chain will get a discount, with this shopper possibly getting the item for free if the price has been driven down to zero inside 24 hours.
</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                          Where can I get more information, support or make a claim?
</button>
                      </h2>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>We do our best to provide you with all the help you may need in our FAQs. If you
need to get in touch with us, you can go to the app Menu &gt; Live Chat.</p> 
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            
          </div>
        </section>
        
        <div className="drawer">
          <div className="drawer-mask" />
          <div className="drawer-content">
            <div className="drawer-header">
            </div>
            <div className="form-group">
            <button className="drawer-close">
          
  <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path d="M1.70064521,14.889838 L7.5,9.09094741 L13.2988842,14.889838 C13.4451393,15.0360474 13.6828096,15.0360474 13.829019,14.889838 L14.889838,13.829019 C15.0360474,13.6828096 15.0360474,13.4451393 14.889838,13.2988842 L9.09094741,7.5 L14.889838,1.70110297 C15.0360474,1.55484782 15.0360474,1.31717749 14.889838,1.17096812 L13.829019,0.110149121 C13.6828096,-0.0360602473 13.4451393,-0.0360602473 13.2988842,0.110149121 L7.5,5.90903971 L1.70110297,0.109691358 C1.55484782,-0.0365637861 1.31717749,-0.0365637861 1.17096812,0.109691358 L0.109691358,1.17046459 C-0.0365637861,1.31671973 -0.0365637861,1.55439006 0.109691358,1.70064521 L5.90903971,7.5 L0.109691358,13.2988842 C-0.0365637861,13.4451393 -0.0365637861,13.6828096 0.109691358,13.829019 L1.17046459,14.889838 C1.31671973,15.0360474 1.55439006,15.0360474 1.70064521,14.889838 Z" fill="#01af4d" fillRule="nonzero" />
    </g>
  </svg>
</button>
              <select className="custom-select" id="cities-select">
                <option selected>Select service</option>
                <option value="khabar">Khabar</option>
                <option value="medicine">Medicine</option>
                <option value="parcel">Parcel</option>
                <option value="shop">Shop</option>
                <option value="fresh">Fresh</option>
                <option value="krishi-ghor">Krishi Ghor</option>
                <option value="sodai-express">Sodai</option>
                <option value="voucher">Voucher</option>
              </select>
            </div>
            <div className="accordion" id="accordionServices" />
          </div>

        </div>
  
  <section className="ptb100 pt60">
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="job-details-info">
          <h2>Explore Value for money products</h2>
          <p align="justify">Anun is a revolutionary social commerce app that has brought first-of-its-kind purchasing opportunities to the people of Bangladesh. With Anun, shoppers can take advantage of group buying offers and local shopping vouchers at reduced prices.

On the Anun platform, shoppers have the option to purchase a product individually or initiate/join a team purchase. By forming teams around desired products, shoppers can enjoy even lower prices. However, it's important to note that an order will only be confirmed once a team is formed.

Anun also offers a unique price chopping feature that allows shoppers to get a free product by forming a big team. Additionally, shoppers can earn rewards through daily or hourly check-ins.

To successfully form a group, shoppers can launch the entire interaction by placing their order with an initial installment. Anun is committed to providing its users with an unparalleled shopping experience, offering great deals and incentives to save money while shopping.</p>

          <h2> Why choose Anun?</h2>
          <p align="justify">
            <span>✓</span> Better user experience 
            <span>✓</span> Price chopping facilities 
            <span>✓</span> Hourly check-ins reward 
            <span>✓</span> Clear information about the offer 
            <span>✓</span> Broad service offer 
            <span>✓</span> Enjoy massive savings 
            <span>✓</span> Frequent promotions 
            <span>✓</span> Simplified online payment through Nagad and bKash 
            <span>✓</span> Comprehensive categories 
            <span>✓</span> Wide offer & easy access 
            <span>✓</span> Fast settlement of a complaint 
            <span>✓</span> Availability of brand products 
            <span>✓</span> Honest reviews of customers 
            <span>✓</span> Fastest delivery 
            <span>✓</span> Best Quick Commerce 
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

      </div>
    )
  }
}
export default Home