import React from 'react'
import { Helmet } from 'react-helmet'
class Terms extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Terms & Conditions | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Terms" />
                    <meta name="title" content="Terms & Conditions | Anun"></meta>
                    <meta name="description" content="To enjoy our services you have to maintain our terms" />
                    <meta name="keywords" content="anun terms and conditions" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:url" content="https://www.anun.xyz/Terms" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Terms & Conditions | Anun" />
                    <meta property="og:description" content="To enjoy our services you have to maintain our terms" />
                    <meta property="og:image" content="/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Terms"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Terms & Conditions | Anun" />
                    <meta name="twitter:description" content="To enjoy our services you have to maintain our terms" />
                    <meta name="twitter:image" content="/assets/images/logo.jpg" />
                    
                </Helmet>
                 
                
        <section class="page-title pt115"> 
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="section-title alt-left">
                            <h2>Terms & Conditions</h2>
                            <p align="justify">Last Update on 20 May, 2021</p>
                        </div> 
                    </div>
                </div>
            </div> 
        </section>
        
         
        <section class="ptb100 pt60">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="job-details-info">
                        <h5>A. Introduction:</h5>
<p align="justify">Welcome to anun.xyz additionally thusly known as "we", "us" or "Anun". We are an online commercial centre and these are the terms and conditions overseeing your entrance and utilization of Anun alongside its related sub-areas, destinations, portable application, administrations and apparatuses (the "Website"). By utilizing the Site, you thusly acknowledge these terms and conditions (counting the connected data in this) and speak to that you consent to conform to these terms and conditions (the "Client Agreement"). This User Agreement is regarded as successful upon your utilization of the Site which means your acknowledgement of these terms. On the off chance that you don't consent to be bound by this User Agreement kindly don't get to, register with or utilize this Site. This Site is claimed and worked by Anun, an organization consolidated under the Companies Act, 1994.</p>
<p align="justify">The Site maintains whatever authority is needed to change, adjust, include, or expel bits of these Terms and Conditions whenever with no earlier warning. Changes will be successful when posted on the Site with no other notification gave. If it's not too much trouble check these Terms and Conditions normally for refreshes. You proceed with the utilization of the Site following the presenting of changes on Terms and Conditions of utilization establishes your acknowledgement of those changes.</p>
<h4>B. CONDITIONS OF USE</h4>
<h5>1. YOUR ACCOUNT</h5>
<p align="justify">To get to specific administrations offered by the stage, we may necessitate that you make a record with us or give individual data to finish the making of a record. We may whenever in our sole and total watchfulness, nullify the username as well as password without giving any explanation or earlier notice and will not be at risk or answerable for any misfortunes endured by, brought about by, emerging out of, regarding or because of such solicitation or negation.</p>
<p align="justify">Any agreement between us, regardless of whether for utilization of the site or in connection to the buy of items or administrations through the site will be represented by the laws of Bangladesh and all gatherings submit to the non-restrictive purview of the Bangladeshi Courts.</p>
<p align="justify">You are answerable for keeping up the classification of your client recognizable proof, password, account subtleties, and related private data. You consent to acknowledge this obligation and guarantee your record and its related subtleties are kept up safely consistently and every essential advance is taken to avoid abuse of your record. You ought to advise us promptly on the off chance that you have any motivation to accept that your secret phrase has gotten known to any other individual, or if the secret word is being, or is probably going to be, utilized in an unapproved way. You concur and recognize that any utilization of the Site and related administrations offered as well as any entrance to private data, information or interchanges utilizing your record and secret word will be esteemed to be either performed by you or approved by you all things considered. You consent to be bound by any entrance of the Site as well as utilization of any administrations offered by the Site (regardless of whether such access or use are approved by you or not). You concur that we will be entitled (yet not obliged) to follow up on, depend on or consider you exclusively capable and subject in regard thereof as though the equivalent were done or transmitted by you. You further concur and recognize that you will be bound by and consent to completely reimburse us against all misfortunes emerging from the utilization of or access to the Site through your record.</p>
<p align="justify">It would be ideal if you guarantee that the subtleties you give us are right and finish consistently. You are committed to refreshing insights regarding your record progressively by getting to your record on the web. For snippets of data you are not ready to refresh by getting to Your Account on the Site, you should advise us to utilize our client care correspondence channels to help you with these changes. We claim all authority to decline access to the Site, end records, evacuate or alter content whenever without earlier notice to you. We may whenever in our sole and total carefulness, demand that you update your Personal Data or forthwith negate the record or related subtleties without giving any explanation or earlier notice and will not be at risk or liability for any misfortunes endured by or brought about by you or emerging out of or regarding or because of such solicitation or nullification. You thusly consent to change your secret key now and again and to keep your record secure and will be answerable for the privacy of your record and obligated for any revelation or use (regardless of whether such use is approved or not) of the username and additionally secret key.</p>
<h5>2. Your account vs your order</h5>
<p align="justify">We Only Consider the name of an anun account holder as the owner of and the client of the product(s) which has been ordered from the respective anun account. You should not request any changes in the name of the owner while delivering the product (i.e.: Motor Bike/Car, apartments, lands etc.)</p>
<p align="justify">Anun retains all kinds of authority to ban/cancel/freeze/unfreeze your account(s) if they think it should be done so. Anun is not bound to provide the balance amount (of cashback)to you if your account is blocked/banned. Any kind of balance paid by you through Nagad/bank/cash would be refunded to you within a reasonable time period. Please note that it may depend on the calculation of your total payment vs the cashback you received and used.</p>
<h5>3. Your Conduct: </h5>
<p align="justify">You should not utilize the site at all that causes, or is probably going to cause, the Site or access to it to interfere, harmed or disabled in any capacity. You should not participate in exercises that could hurt or conceivably hurt the Site, its workers, officials, delegates, partners or some other gathering legitimately or in a roundabout way connected with the Site or access to it to be intruded, harmed or debilitated in any capacity. You comprehend that you, and not us, are answerable for every single electronic correspondence and substance sent from your PC to us and you should utilize the Site for legal purposes as it were. You are carefully restricted from utilizing the Site</p>
<p align="justify">anun.xyz might not be subject to any individual for any misfortune or harm, which may emerge from the utilization of any of the data contained in any of the materials on this site.</p>
<ul>
   <li><i class="far fa-check-circle"></i>  for deceitful purposes, or regarding a criminal offense or other unlawful movements</li>
   <li><i class="far fa-check-circle"></i>  to send, utilize or reuse any material that doesn't have a place with you; or is illicit, hostile (counting yet not constrained to material that is explicitly unequivocal substance or which advances prejudice, dogmatism, scorn or physical mischief), tricky, misdirecting, damaging, foul, hassling, disrespectful, abusive, derogatory, vulgar, obscene, pedophilic or threatening; ethically questionable, stigmatizing or in break of copyright, trademark, secrecy, protection or some other exclusive data or right; or is generally damaging to outsiders; or identifies with or advances tax evasion or betting; or is unsafe to minors in any capacity; or mimics someone else; or undermines the solidarity, trustworthiness, security or power of Bangladesh or neighborly relations with remote States; or offensive or generally unlawful in any way at all; or which comprises of or contains programming infections, political battling, business sales, networking letters, mass mailings or any ‘spam‘</li>
   <li><i class="far fa-check-circle"></i>  Use the Site for illicit purposes. </li>
   <li><i class="far fa-check-circle"></i>  to cause irritation, bother or unnecessary nervousness</li>
   <li><i class="far fa-check-circle"></i>  for whatever other purposes that is other than what is expected by us</li>
</ul>
<h5>4. Submission:</h5>
<p align="justify">Anything that you submit to the Site or potentially give to us, including yet not restricted to, questions, audits, remarks, and proposals (on the whole, "Entries") will turn into our sole and selective property and will not become back to you. Notwithstanding the rights pertinent to any Submission, when you present remarks or surveys on the Site, you additionally award us the privilege to utilize the name that you submit, regarding such audit, remark, or other substance. You will not utilize a bogus email address, profess to be somebody other than yourself or generally misdirect us or outsiders with regards to the cause of any Submissions. We may, yet will not be committed to, expel or alter any Submissions with no notification or lawful course appropriate to us in such manner.</p>
<h5>5. CLAIMS AGAINST OBJECTIONABLE CONTENT:</h5>
<p align="justify">We list a huge number of items including apartments &amp; Lands available to be purchased offered by various dealers on the Site and host different remarks on postings, it isn't workable for us to know about the substance of every item recorded available to be purchased or each remark or survey that is shown. Appropriately, we work on a "guarantee, audit and takedown" premise. On the off chance that you accept that any substance on the Site is illicit, hostile (counting yet not restricted to material that is explicitly express substance or which advances prejudice, bias, scorn or physical mischief), beguiling, deluding, harsh, profane, irritating, impious, slanderous, offensive, revolting, obscene, pedophilic or threatening; ethnically questionable, criticizing; or is generally damaging to outsiders; or identifies with or advances tax evasion or betting; or is destructive to minors in any capacity; or mimics someone else; or compromises the solidarity, trustworthiness, security or sway of Bangladesh or cordial relations with remote states; or shocking or generally unlawful in any way at all; or which comprises of or contains programming infections, (" frightful substance "), please tell us quickly by following by keeping in touch with us on hello@anun.xyz. We will make every single useful undertaking to examine and evacuate substantial offensive substance griped about inside a sensible measure of time.</p>
<p align="justify">If it's not too much trouble guarantee to give your name, address, contact data and the same number of pertinent subtleties of the case including the name of frightful substance party, occasions of protest, confirmation of complaint among others. If you don't mind note that giving inadequate subtleties will ruin your case and unusable for legitimate purposes.</p>
<h5>6. CLAIMS AGAINST INFRINGING CONTENT:</h5>
<p align="justify">We regard the licensed innovation of others. On the off chance that you accept that your licensed innovation rights have been utilized such that offers to ascend to worries of encroachment if it's not too much trouble illuminate us and we will endeavour every single sensible exertion to address your worry inside a sensible measure of time. It would be ideal if you guarantee to give your name, address, contact data and the same number of pertinent subtleties of the case including the name of encroaching gathering, cases of encroachment, and verification of encroachment among others. If you don't mind note that giving deficient subtleties will ruin your case and unusable for lawful purposes. What's more, giving bogus or misdirecting data might be viewed as a legitimate offence and might be trailed by lawful procedures.</p>
<p align="justify">We likewise regard a maker's entitlement to go into selective appropriation or resale understandings for its items. In any case, infringement of such understandings doesn’t establish licensed innovation rights encroachment. As the implementation of these understandings is an issue between the producer, wholesaler and additionally particular affiliate, it would not be befitting for us to aid the authorization of such exercises. While we can't give legitimate guidance, nor share private data as secured by the law, we suggest that any inquiries or concerns concerning your privileges might be steered to lawful authority.</p>
<h5>7. Indemnity</h5>
<p align="justify">You will repay and hold innocuous Anun, its auxiliaries, offshoots, and their particular officials, executives, operators, and representatives, from any case or request, or activities including sensible lawyer's expenses, made by any outsider or punishment forced due to or emerging out of your break of these Terms and Conditions or any record joined by reference, or your infringement of any law, rules, guidelines or the privileges of an outsider.</p>
<p align="justify">You therefore explicitly discharge Anun as possessed by offshoots as well as any of its officials and delegates from any cost, harm, obligation or other outcomes of any of the activities/inactions of the merchants or other specialist co-ops and explicitly waiver any cases or requests that you may have for this benefit under any resolution, contract or something else.</p>
<h5>8. Losses</h5>
<p align="justify">We won't be answerable for any business or individual misfortunes (counting however not restricted to loss of benefits, income, contracts, foreseen investment funds, information, altruism, or squandered consumption) or whatever other backhanded or weighty misfortune that isn't sensibly predictable to both you and us when you initiated utilizing the Site.</p>
<h5>9. Corrections TO CONDITIONS OR ALTERATIONS OF SERVICE AND RELATED PROMISE</h5>
<p align="justify">We maintain all authority to make changes to the Site, its strategies, these terms and conditions and some other freely showed condition or administration guarantee whenever. You will be dependent upon the approaches and terms and conditions in power at the time you utilized the Site except if any change to those strategies or these conditions is required to be made by law or government expert (in which case it will apply to orders recently set by you). If any of these conditions is regarded invalid, void, or in any way, shape or form unenforceable, that condition will be considered severable and won't influence the legitimacy and enforceability of any outstanding condition.</p>
<h5>10. WAIVER </h5>
<p align="justify">You recognize and perceive that we are a private business endeavour and claim all authority to lead the business to accomplish our targets in a way we esteem fit. You additionally recognize that if you break the conditions expressed on our Site and we make no move, we are as yet qualified for utilizing our privileges and cures in whatever other circumstance where you rupture these conditions.</p>
<h5>11. Administering LAW AND JURISDICTION </h5>
<p align="justify">These terms and conditions are administered by and interpreted as per the laws of The People's Republic of Bangladesh. You concur that the courts, councils and additionally semi-legal bodies situated in Dhaka, Bangladesh will have the restrictive purview on any question emerging inside Bangladesh under this Agreement.</p>
<h5>12. Offers:</h5>
<p align="justify">Anun is not liable or bound to provide any other offers of any merchant/importer/ vendors’ at any other places/sites except Anun. You can just request/ask/solicitation or look for data about the offers given/declared by Anun.</p>
<p align="justify">One client will have the option to buy one item during any special offers (*if not allowed and announced multiple quantities by Anun).</p>
<p align="justify">Customers cannot claim to withdraw any kind of cashback by any other form (example - by Nagad, bank or cash) except anun balance. </p>
<h5>13. Valuing, AVAILABILITY AND ORDER PROCESSING</h5>
<p align="justify">All costs are recorded in Bangladeshi Taka (BDT) and are comprehensive of VAT and are recorded on the Site by the merchant that is selling the item or administration. Things in your Shopping Cart will consistently mirror the latest value shown on the thing's item detail page. If it's not too much trouble note that this cost may vary from the value that appeared for the thing when you previously put it in your truck. Putting a thing in your truck doesn't save the value that appeared around then. It is additionally conceivable that a thing's cost may diminish between the time you place it in your bin and the time you buy it.</p>
<p align="justify">We don't offer value coordinating for any things sold by any merchant on our Site or different sites.</p>
<p align="justify">We are resolved to give the most exact evaluating data on the Site to our clients; be that as it may, blunders may at present happen, for example, situations when the cost of a thing isn't shown effectively on the Site. In that capacity, we maintain all authority to won't or drop any request. If a thing is mispriced, we may, at our own tact, either get in touch with you for guidelines or drop your request and advise you of such crossing out. We will reserve the option to can't or drop any such requests whether the request has been affirmed and your prepayment handled. On the off chance that such a retraction happens on your prepaid request, our approaches for the discount will apply. If it's not too much trouble note that Anun has 100% right on the discount sum. Generally, the discount sum is determined dependent on the client followed through on cost in the wake of deducting any kind of markdown and delivery charge.</p>
<p align="justify">We list accessibility data for items recorded on the Site, remembering for every item data page. Past what we state on that page or generally on the Site, we can't be increasingly explicit about accessibility. It would be ideal if you note that dispatch gauges are only that. They are not ensured dispatch times and ought not to be depended upon in that capacity. As we process your request, you will be educated by email or SMS if any items you request end up being inaccessible.</p>
<p align="justify">It would be ideal if you note that there are situations when a request can't be prepared for different reasons. The Site maintains whatever authority is needed to won't or drop any request in any way, shape or form at some random time. You might be approached to give extra confirmation or data, including yet not constrained to telephone numbers and address, before we acknowledge the request.</p>
<p align="justify"> To keep away from any extortion with credit or check cards, we maintain whatever authority is needed to acquire approval of your instalment subtleties before furnishing you with the item and to confirm the individual data you imparted to us. This confirmation can take the state of a character, spot of habitation, or banking data check. The nonappearance of an answer following such a request will naturally cause the wiping out of the request inside a sensible timetable. We maintain whatever authority is needed to continue to coordinate undoing of a request for which we presume a danger of false utilization of banking instruments or different reasons without earlier notice or any ensuing lawful obligation.</p>
<p align="justify">If there are any mistakes found regarding pricing (example- overpriced than the MRP or lower price than the MRP) of any product and if the seller delivers that mispriced product even after notified by the customer and if any action is not taken by the seller even after notified by the customer then the seller will be solely legally liable for this.</p>
<h5>14. Special Items</h5>
<h6>Security and Fraud</h6>
<ul>
   <li><i class="far fa-check-circle"></i>  When you utilize a voucher, you warrant to Anun that you are the appropriately approved beneficiary of the voucher and that you are utilizing it in compliance with common decency.</li>
   <li><i class="far fa-check-circle"></i>  If you recover, endeavour to reclaim or support the recovery of the voucher to acquire limits to which you are not entitled you might be perpetrating a common or criminal offence </li>
   <li><i class="far fa-check-circle"></i>  If we sensibly accept that any voucher is being utilized unlawfully or illicitly, we may reject or drop any voucher/request and you concur that you will have no case against us in regard of any dismissal or crossing out. Anun maintains whatever authority is needed to make any further move it considers suitable in such occasions</li>
</ul>
<h5>15. Mass Purchasing</h5>
<p align="justify">Anun sites are accessible for non-business and residential utilization as it were. We maintain all authority to reject or cross out your request on the off chance that we trust you are requesting our items for business or other re-deal purposes. We apply breaking points to some of our items to ensure stock accessibility so that whatever number of our clients as could be expected under the circumstances can purchase our items.</p>
<h5>16. RESELLING ANUN PRODUCTS</h5>
<p align="justify">Reselling Anun products for business purposes is strictly prohibited.If any unauthorized personnel are found committing the above act, legal action may be taken against him/her. If it is found and proven that any order has been placed to cash out the paid amount, legal action may be taken against him/her. If any seller orders (as a customer) from their own shop using cashback or any other balance it would be considered as a violation of the activities mentioned above.In that case seller (as seller account) &amp; the customer (as customer account) would be bound to oblige the decision taken by anun as well as legal action may be taken.</p>
<h5>17. Stock availability</h5>
<p align="justify">The orders are subject to availability of stock. If there is any problem with stock then the order can be cancelled at any time, irrespective of any duration.</p>
<h5>18. Delivery Timeline</h5>
<p align="justify">The delivery might take longer than the usual timeframe/line to be followed by Anun. The delivery timeframe is only subject to estimation and please consider and calculate the announced timeframe as working days. It might vary depending upon the circumstances. Delivery might be delayed due to force majeure events which include, but not limited to, political unrest, political event, national/public holidays/official holiday(s), etc.</p>
<h5>19. Delivery Charge(s)</h5>
<p align="justify">anun will bear delivery charges for the order(s) with the following conditions unless otherwise there is any specific campaign wise instruction for delivery charge.</p>
<p align="justify">19.1. Weight must be no more than 10 Kgs.</p>
<p align="justify">19.2. Size Must be within 1 cubic foot.</p>
<p align="justify">Please note that,</p>
<p align="justify">i) Fragile and/or Sensitive items (example Electronics, Home appliances, decorative items, crockeries etc.) may be excluded from the above conditions.</p>
<p align="justify">ii) Delivery charge(s) may applicable if the seller delivers the product(s).</p>
<p align="justify">iii) Waiver of the delivery charge(s) may depend on different circumstances such as delivery area, road conditions, transportations cost etc. </p>
<p align="justify">iv) liquid items, gymnasium instruments and any kind of product that may require special carrying facilities are excluded from the above condition.</p>
<p align="justify">v) If collection or delivery of a Consignment takes place at your premises, we shall not be under any obligation to provide any equipment or labour or any associated cost(s) which, apart from the driver collecting the Consignment, may be required for loading or unloading of a Consignment. The delivery charge does not include any installation cost. Installation cost (if there is any) is subject to the supplier’s policy. </p>
<p align="justify">vi) Provision of the home delivery is/are subject to carrier and sellers’ delivery policy.</p>
<p align="justify">vii) If any delivery fails due to customer negligence or any other reason from the customer end then the customer is bound to pay the return courier charge(s) as well as the redelivery charge(s).</p>
<h5>20. Returns Policy </h5>
<p align="justify">1.If your item is faulty/harmed or mistaken/deficient at the hour of conveyance, if you don't mind, please get in touch with us inside the pertinent return window. Your item might be qualified for discount or substitution relying upon the item class and condition.</p>
<p align="justify">2. Please note that a few items are not qualified for arrival if the item is "Never again required"</p>
<p align="justify">3. For gadget related issues after use or the lapse of the arrival window, we will allude you to the brand guarantee focus (if relevant). </p>
<p align="justify">4. Please note that you should keep and be able to provide proper documentation and proof about your return/refund claim (i.e. unboxing video, receiving invoice, etc.).</p>
<h5>21. Cancellation</h5>
<p align="justify">Anun retains unqualified right to cancel any order at its sole discretion before dispatch and for any reason which may include, but not limited to, the product being mispriced, out of stock, expired, defective, malfunctioned, and containing incorrect information or description arising out of technical or typographical error or for any other reason.</p>
<h5>22. Refund</h5>
<p align="justify">In case of any cancellations of any order(s) due to inevitable circumstances the paid amount would be refunded as the customer's anun account firstly. Customer need to report an issues refund category and should mention his/her desired refund type(i.e. Nagad/bank) along with details (Nagad personal number, bank account details)</p>
<p align="justify">If any order got cancelled, then the customer will get refunded with his/her principal amount (paid amount) and will be disqualified for any kind of offers or cashback associated with the particular order.</p>
<p align="justify">The refund process may take up to 30 working days or more depending upon the payment service gateway provider’s clauses. The refund will be initiated within the same payment channel i.e. if the customer pays via bKash then the customer will be refunded through bKash. The customer cannot be refunded via any other payment channels. But Anun retains the right and has the right to consider/reconsider refund via another flexible channel(s) to make the refund system faster or to make it easier for the customer.</p>
<p align="justify">The customer will not be eligible for any extra cashback/bonus against his principal amount in case of refunds. Anun is not bound to pay any kind of service charge or cost for the refunded amount.</p>
<h5>23. Representations AND WARRANTIES</h5>
<p align="justify">We don't make any portrayal or guarantee as to points of interest, (for example, quality, esteem, saleability, and so forth) of the items or administrations leaned to be sold on the Site when items or administrations are sold by outsiders. We don't certainly or expressly bolster or embrace the deal or acquisition of any items or administrations on the Site. We acknowledge no obligation for any blunders or exclusions, regardless of whether for the benefit of itself or outsiders.</p>
<p align="justify">We are not liable for any non-execution or break of any agreement that went into between you and the merchants. We can't and don't ensure your activities or those of the dealers as they finish up exchanges on the Site. We are not required to intercede or resolve any debate or contradiction emerging from exchanges happening on our Site.</p>
<p align="justify">We don't any time of time during any exchange as went into by you with an outsider on our Site, gain title to or have any rights or claims over the items or administrations offered by a dealer. In this way, we don't have any commitments or liabilities regarding such contract(s) that went into between you and the seller(s). We are not answerable for inadmissible or postponed execution of administrations or harms or deferrals because of items that are out of stock, inaccessible or delay purchased.</p>
<p align="justify">Valuing on any product(s) or related data as thought about the Site may because of some specialized issue, typographical blunder or other explanation by the off base as distributed and therefore you acknowledge that in such conditions the vendor or the Site may drop your request without earlier notice or any obligation emerging subsequently. Any prepayments made for such requests will be discounted to you per our discount arrangement as stipulated</p>
<p align="justify">**** You affirm that the product(s) or service(s) requested by you are obtained for your interior/individual utilization and not for business re-deal. You approve of us to proclaim and give a statement to any legislative expert for your benefit expressing the aforementioned reason for your requests on the Site. The Seller or the Site may drop a request wherein the amounts surpass the run of the mill singular utilization. This applies both to the number of items requested inside a solitary request and the putting in of a few requests for a similar item where the individual requests include an amount that surpasses the run of the mill singular utilization. What involves a run of the mill person's utilization amount limit will be founded on different components and at the sole watchfulness of the Seller or our own and may fluctuate from individual to person.</p>
<p align="justify">You may drop your request at no cost whenever before the thing is dispatched to you.</p>
<p align="justify">If you don't mind note that we sell items just in amounts that relate to the run of the mill needs of a normal family. This applies both to the number of items requested inside a solitary request and the putting in of a few requests for a similar item where the individual requests include an amount run of the mill for an ordinary family unit. It would be ideal if you audit our Refund Policy.</p>

                           
                        </div>
                    </div>
                </div>
            </div>
        </section>

            </div>

        )
    }


}

export default Terms