import React from 'react'
import { Helmet } from 'react-helmet'

class Careers extends React.Component {

  render() {

    return (

      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers | Anun</title>
          <link rel="canonical" href="https://www.anun.xyz/Careers" />
          <meta name="title" content="Careers | Anun"></meta>
          <meta name="description" content="At Anun, you’ll do more than join something — you’ll add something. Discover what a career at Anun could mean for you. " />
          <meta name="keywords" content="anun jobs, anun careers" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        

          <meta property="og:url" content="https://www.anun.xyz/Careers" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Careers | Anun" />
          <meta property="og:description" content="At Anun, you’ll do more than join something — you’ll add something. Discover what a career at Anun could mean for you." />
          <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

          <meta property="twitter:url" content="https://www.anun.xyz/Careers"></meta>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Careers | Anun" />
          <meta name="twitter:description" content="At Anun, you’ll do more than join something — you’ll add something. Discover what a career at Anun could mean for you." />
          <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
        </Helmet>
        <section className="join-scetion about-scetion pt100">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="section-title">
                  <h2>Who are we? </h2>
                  <p>At Anun we believe we can create jobs to contribute to the betterment of human beings, improve the quality of life through services.
                  And by doing this we can all be happy together.
            </p>
                </div>
              </div>
            </div>
            <div className="row mb120">
              <div className="col-12 col-md-7">
                <div className="about-image">
                  <img src="./assets/images/career1.jpg" alt="career" />
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="about-info pl35">
                  <h2>We are smart </h2>
                  <p>We never stop learning. We think deeply, asking questions over and over again if we don't understand something </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-7 order-2">
                <div className="about-image">
                  <img src="./assets/images/career2.jpg" alt="career" />
                </div>
              </div>
              <div className="col-12 col-md-5 order-1">
                <div className="about-info pr35">
                  <h2>We are creative</h2>
                  <p>We do not have the challenge of fulfilling what we have imagined and we meet the challenges </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ptb100 bg-gray-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="section-title">
                  <h2>Go further with Anun</h2>
                  <p>Want to be a part of the grocery revolution? Join the team.</p>
                </div>
              </div>
            </div>
           
  <section className="pb100">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div id="engineering-team" className="job-list-wrapper">
            <div className="job-category">
              <h2>Engineering team</h2>
            </div>
            <div className="job-list">
              <ul className="list-unstyled">
                <li>
                  <span className="job-title">
                    Design Systems Engineer – Mobile iOS
                  </span>
                  <span className="job-department">
                    Technology Department, Product Dhaka, Bangladesh
                  </span>
                  <span>
                    <a href="job-details.html" className="btn btn-solid alt">
                      View Details
                    </a>
                  </span>
                </li>
                <li>
                  <span className="job-title">iOS Engineer</span>
                  <span className="job-department">
                    iOS Development, Software Engineering
                  </span>
                  <span>
                    <a href="job-details.html" className="btn btn-solid alt">
                      View Details
                    </a>
                  </span>
                </li>
                <li>
                  <span className="job-title">
                    iOS Development, Software Engineering
                  </span>
                  <span className="job-department">
                    Analytics, Data &amp; Analytics, Engineering &amp; IT,
                    Software Engineering
                  </span>
                  <span>
                    <a href="job-details.html" className="btn btn-solid alt">
                      View Details
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div id="design-team" className="job-list-wrapper">
            <div className="job-category">
              <h2>Design Team</h2>
            </div>
            <div className="job-list">
              <ul className="list-unstyled">
                <li>
                  <span className="job-title">UX Researcher</span>
                  <span className="job-department">
                    Technology Department, Design Dhaka, Bangladesh
                  </span>
                  <span>
                    <a
                      href="job-details-ux-researcher.html"
                      className="btn btn-solid alt"
                    >
                      View Details
                    </a>
                  </span>
                </li>
                <li>
                  <span className="job-title">UI Designer</span>
                  <span className="job-department">
                    Technology Department, Design Dhaka, Bangladesh
                  </span>
                  <span>
                    <a
                      href="job-details-ui-designer.html"
                      className="btn btn-solid alt"
                    >
                      View Details
                    </a>
                  </span>
                </li>
                <li>
                  <span className="job-title">Product Designer</span>
                  <span className="job-department">
                    Technology Department, Design Dhaka, Bangladesh
                  </span>
                  <span>
                    <a
                      href="job-details-product-designer.html"
                      className="btn btn-solid alt"
                    >
                      View Details
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div id="data-science-team" className="job-list-wrapper">
            <div className="job-category">
              <h2>Data Science Team</h2>
            </div>
            <div className="job-list">
              <ul className="list-unstyled">
                <li>
                  <span className="job-title">
                    Design Systems Engineer – Mobile iOS
                  </span>
                  <span className="job-department">
                    Engineering &amp; IT, Mobile Development, Software
                    Engineering
                  </span>
                  <span>
                    <a href="job-details.html" className="btn btn-solid alt">
                      View Details
                    </a>
                  </span>
                </li>
                <li>
                  <span className="job-title">iOS Engineer</span>
                  <span className="job-department">
                    iOS Development, Software Engineering
                  </span>
                  <span>
                    <a href="job-details.html" className="btn btn-solid alt">
                      View Details
                    </a>
                  </span>
                </li>
                <li>
                  <span className="job-title">
                    iOS Development, Software Engineering
                  </span>
                  <span className="job-department">
                    Analytics, Data &amp; Analytics, Engineering &amp; IT,
                    Software Engineering
                  </span>
                  <span>
                    <a href="job-details.html" className="btn btn-solid alt">
                      View Details
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div id="product-team" className="job-list-wrapper">
            <div className="job-category">
              <h2>Product Team</h2>
            </div>
            <div className="job-list">
              <ul className="list-unstyled">
                <li>
                  <span className="job-title">Senior Product Manager</span>
                  <span className="job-department">
                    Technology Department, Product Dhaka, Bangladesh
                  </span>
                  <span>
                    <a
                      href="job-details-senior-product-manager.html"
                      className="btn btn-solid alt"
                    >
                      View Details
                    </a>
                  </span>
                </li>
                <li>
                  <span className="job-title">Product Manager</span>
                  <span className="job-department">
                    Technology Department, Product Dhaka, Bangladesh
                  </span>
                  <span>
                    <a
                      href="job-details-product-manager.html"
                      className="btn btn-solid alt"
                    >
                      View Details
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
           
          </div>
        </section>
      </div>

    )
  }


}

export default Careers