import React from 'react'
import { Helmet } from 'react-helmet'
class Contact extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Contact Us | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Contact" />
                    <meta name="title" content="Contact Us | Anun"></meta>
                    <meta name="description" content="See how to contact at Anun Support" />
                    <meta name="keywords" content="anun help line" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:url" content="https://www.anun.xyz/Contact" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Contact Us | Anun" />
                    <meta property="og:description" content="See how to contact at Anun Support" />
                    <meta property="og:image" content="/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Contact"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Contact Us | Anun" />
                    <meta name="twitter:description" content="See how to contact at Anun Support" />
                    <meta name="twitter:image" content="/assets/images/logo.jpg" />
                    
                </Helmet>
                
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Contact Us</h2>
            
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <section className="ptb100 pt60">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="job-details-info">
          <p align="justify"> <b>Bangladesh Office:&nbsp;&nbsp;</b> Anun Oxyzen, Ashulia, Savar, Dhaka <br/> <b>Email:</b> hello@anun.xyz <br/> <b>Help Line:</b> +880 9696855001 <br/> <b>Live Chat:</b><a href="/LiveChat"> Click Here</a></p> 
          <br/><p align="justify"> <b>United Kingdom Office:&nbsp;&nbsp;</b>Anun Campus, 12 Constance Street, London, England <br/> <b>Email:</b> hello@anun.xyz<br/> <b>Live Chat:</b><a href="/LiveChat"> Click Here</a></p> 
          </div>
        </div>
      </div>
    </div>
    
    </section>

            </div>

        )
    }


}

export default Contact