import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
class Brand extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Brand Guidelines | Anun</title>
          <link rel="canonical" href="https://www.anun.xyz/Brand" />
          <meta name="title" content="Brand Guidelines | Anun"></meta>
          <meta name="description" content="We've prepared these brand guidelines so you'll know exactly how you can–and cannot–display our Brand Assets." />
          <meta name="keywords" content="anun,anun brand, brand guidelines anun" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
         

          <meta property="og:url" content="https://www.anun.xyz/Brand" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Brand Guidelines | Anun" />
          <meta property="og:description" content="We've prepared these brand guidelines so you'll know exactly how you can–and cannot–display our Brand Assets." />
          <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

          <meta property="twitter:url" content="https://www.anun.xyz/Brand"></meta>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Brand Guidelines | Anun" />
          <meta name="twitter:description" content="We've prepared these brand guidelines so you'll know exactly how you can–and cannot–display our Brand Assets." />
          <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
        </Helmet>
        <section className="page-title pt115">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="section-title alt-left">
                  <h2>Brand Guidelines</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ptb100 pt60">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="job-details-info">
                  <center>
                    {" "}
                    <h2>
                    We've created brand guidelines for you </h2>
                      <br></br>
                      <p>To understand What you can and cannot display of Anun's brand assets.
                    </p>
                    <Link
                      to="/assets/brand.pdf"
                      className="btn btn-outline-success"
                    >
                      Download Now
                    </Link>

                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Brand;
