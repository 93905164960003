import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setYear(new Date().getFullYear());
        }, 60000); // update the year every minute

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <footer>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="footer-inner pt100 pb60">

                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <img src="assets/images/footer.svg" height={57} weidth={48} alt="" />
                                            </div>
                                            <div className="footer-info">
                                              
                                                <p>ANUN OXYZEN, Dhaka, Bangladesh </p>
                                                &nbsp;
                                                <p>UK Office: 12 Constance Street, London, England</p>
                                                &nbsp;
                                                <p> hello@anun.xyz</p>
                                                &nbsp;
                                                <p>+8809696855001 </p><p>(24/7 Available)
                                                </p>

                                                </div>
                                                
                                               
                                                
                                        </div>
                                   
                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <h2>Quick Links</h2>
                                            </div>
                                            <div className="footer-menu">
                                                <ul className="list-unstyled">
                                                    <li><Link to={{ pathname: "https://rider.anun.xyz" }} target="_blank">Earn Now </Link> </li>
                                                    <li><Link to="/Partner">Become a Partner </Link> </li>
                                                    <li><Link to="/Livechat">Live Support</Link> </li>
                                                    <li><Link to={{ pathname: "http://status.anun.xyz" }} target="_blank">Server Status</Link> </li>
                                                    <li><Link to={{ pathname: "https://verify.anun.xyz" }} target="_blank">Verification System</Link> </li>
                                                    <li><Link to="/Contact">Contact Us</Link> </li>
                                                </ul>
                                                
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <h2>Company Info</h2>
                                            </div>
                                            <div className="footer-menu">
                                                <ul className="list-unstyled">
                                                    <li><Link to="/Story">Our Story</Link> </li>
                                                    <li><Link to="/Careers">Join The Team <span className="badge badge-light">Hiring now</span></Link> </li>
        
                                                    <li><Link to="/Brand" >Brand Guidelines</Link> </li>
                                                    <li><Link to="/Events" >Events</Link> </li>
                                                    <li><Link to="/Leadership" >Leadership</Link> </li>
                                                    <li><Link to="/Media" >Media Coverage</Link> </li>
                                                    <li><Link to="/Investors" >Investor Relations</Link> </li>
     
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <h2>Others</h2>
                                            </div>
                                            <div className="footer-menu">
                                                <ul className="list-unstyled">
                                                    <li><Link to="/Privacy" >Privacy Policy </Link> </li>
                                                    <li><Link to="/Terms" >Terms of Use</Link> </li>
                                                    <li><Link to="/Guideline" >User Manual</Link> </li>
                                                    <li><Link to="/Faq" >FAQ <span className="badge badge-pill badge-light">Updated</span></Link> </li>
                                                    <li><Link to="/sitemap.xml" target="_parent">Sitemap</Link> </li>
                                                    &nbsp;
                                                    <li>
                                                        <i className="fas fa-map-marker-alt" /> 100% Global Startup</li>
                                                        
                                                       
                                                         
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copy-right-part">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="footer-inner pt30 pb30">
                                            <div className="row">
                                                <div className="col-12 col-md-6 order-2">
                                                    <ul className="list-inline app-download-link">
                                                        <h3><span style={{ color: 'white' }}>Put Us</span> In Your Pocket! </h3>
                                                        <li className="list-inline-item">
                                                            <a href="https://play.google.com/store/apps/details?id=app.anun.xyz&hl=en" target="_parent"><img src="assets/images/play-store.png" alt="play store" /></a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a href="https://play.google.com/store/apps/details?id=app.anun.xyz&hl=en" target="_parent"><img src="assets/images/app-galary.png" height={150} weidth={138} alt="play store" /></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 order-1">
                                                    <div className="copy-right">
                                                        <p>Copyright <i className="far fa-copyright" /> Anun Limited 2019-{year}. All Rights Reserved</p>
                                                        <p className="made-in-bd">Registrar of Companies (England and Wales). Company number - <span className="badge badge-light"> 14355185 </span></p>
                                                    
                                                   
                                                   
                                                   
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }


export default Footer;