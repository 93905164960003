import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
class Partner extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Partnership | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Partner" />
                    <meta name="title" content="Partnership | Anun"></meta>
                    <meta name="description" content="Partnership Portal of Anun" />
                    <meta name="keywords" content="anun partner" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    
                    <meta property="og:url" content="https://www.anun.xyz/Partner" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Partnership | Anun" />
                    <meta property="og:description" content="Partnership Portal of Anun" />
                    <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="hhttp://www.anun.xyz/Partner"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Partnership | Anun" />
                    <meta name="twitter:description" content="Partnership Portal of Anun" />
                    <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
                </Helmet>
                
                <section class="about-scetion ptb100">
                    <div class="container">
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                               
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>Maximize Your Reach: Tap into Our Distribution Network</h2>
                                    <p>Reach millions of potential customers and increase your sales with our distribution network. We'll showcase your product and take care of the logistics so you can focus on growing your business. Contact us today to learn more about our proven track record and how we can help you expand your reach.</p>
                                </div>
                            </div>
                        </div>
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7 order-2">
                                <div class="about-image">
                              
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-1">
                                <div class="about-info pr35">
                                    <h2>Collaborate for Growth</h2>
                                    <p>This brief message highlights the value of social media collaborations for business growth. The title emphasizes the idea of "growing together," while the message itself encourages businesses to leverage each other's social media followings to reach more potential customers. The overall tone is optimistic and emphasizes the benefits of collaboration, positioning it as a powerful tool for achieving business success on social media.</p>
                                </div>
                            </div>
                        </div>
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                    
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>Access Live Sales and Inventory Data</h2>
                                    <p>Our local partners program offers exclusive access to a live dashboard with real-time sales and inventory data. With this information, you can make informed business decisions and optimize your operations to drive success. Don't miss out on this valuable opportunity to stay ahead of the competition. Join our local partners program today and get the data you need to succeed.</p>
                                </div>
                            </div>
                        </div>
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7 order-2">
                                <div class="about-image">
                                    
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-1">
                                <div class="about-info pr35">
                                    <h2>Empowering Local Partners</h2>
                                    <p>This statement highlights a company's decision to empower local partners by giving them full control over ordering and replenishing processes. This approach allows for more flexibility and responsiveness to local market needs and conditions. By putting the power in the hands of local partners, the company is showing a commitment to working collaboratively and building strong relationships with the communities they serve.</p>
                                </div>
                            </div>
                        </div>
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                   
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>Interested in listing your products with Anun?</h2>
                                    <p>Anun is an online platform where sellers and buyers can directly connect with each other. If you are interested in listing your products with us, we encourage you to use the secure and transparent link provided below to tell us about your business in a few easy steps. We are always looking for new and innovative products to add to our assortment, and we will get back to you as soon as we have an opportunity. Join the Anun community today!</p>

                                    <Link to={{ pathname: "https://forms.gle/FcZb7NDaKhgbD2CF7" }} className="btn btn-success btn-lg btn-block" target="_parent"> [Just Tap Here]</Link>
                                </div>
                            </div>
                        </div>
                        <h4>* Offerings may vary depending on the individual contracts.</h4>
                        </div>
                        </section>
                        <section id="faq">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2>Top Questions</h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        How can I list my products on Anun?
</button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>To list your products on Anun, simply use the secure and transparent link provided on our website to tell us about your business in a few easy steps. We'll review your information and get back to you as soon as we have an opportunity in our assortment.</p>
                        
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What types of products are accepted on Anun?
</button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>We accept a wide variety of products on Anun, from electronics and household items to clothing and accessories. However, we do have certain restrictions on the types of products that can be sold, such as illegal or counterfeit items.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What are the fees for selling on Anun?
</button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Anun charges a commission on the sale of each item, as well as a processing fee. The exact fees vary depending on the product category, so we encourage sellers to review our fee schedule before listing their products. </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        How do I manage my inventory and orders on Anun?
</button>
                      </h2>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>
                        Anun provides a user-friendly platform for managing inventory and orders. You can easily track your sales and inventory levels, as well as communicate with buyers and manage shipping and returns.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="mb-0">
                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                        What support does Anun provide for sellers?
</button>
                      </h2>
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Anun provides a user-friendly platform for managing inventory and orders. You can easily track your sales and inventory levels, as well as communicate with buyers and manage shipping and returns.</p> 
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
            
          </div>
        </section>
        
    </div>
    
       )
    }


}

export default Partner