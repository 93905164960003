import React from 'react'
import { Helmet } from 'react-helmet'
class Media extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Media Coverage | Anun</title>
                    
                    <link rel="canonical" href="https://www.anun.xyz/Media" />
                    <meta name="title" content="Media Coverage | Anun"></meta>
                    <meta name="description" content="Anun Media is the source for news about Anun." />
                    <meta name="keywords" content=" anun press corner, media coverage " />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:url" content="https://www.anun.xyz/Media" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Media Coverage | Anun" />
                    <meta property="og:description" content="Anun Media is the source for news about Anun." />
                    <meta property="og:image" content="/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Media"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Media Coverage | Anun" />
                    <meta name="twitter:description" content="Anun Media is the source for news about Anun." />
                    <meta name="twitter:image" content="/assets/images/logo.jpg" />
                    
                </Helmet>
                
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Written about our dreams</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <section className="ptb100 pt60">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="job-details-info">
            <style dangerouslySetInnerHTML={{__html: "\n#customers {\n  font-family: \"Trebuchet MS\", 'SolaimanLipi', Arial, Helvetica, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n}\n\n#customers td, #customers th {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n#customers tr:nth-child(even){background-color: #f2f2f2;}\n\n#customers tr:hover {background-color: #ddd;}\n\n#customers th {\n  padding-top: 12px;\n  padding-bottom: 12px;\n  text-align: left;\n  background-color: #01af4d;\n  color: white;\n}\n" }} />
            <table id="customers">
              <tbody><tr>
                  <th>Date</th>
                  <th>Newspaper</th>
                  <th>Title</th>
                </tr>
                <tr>
                  <td>04 July, 2020</td>
                  <td><img src="https://www.jugantor.com/frontend/assets/images/logo_main3860.png?v=1" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://www.jugantor.com/todays-paper/it-world/322431/%E0%A6%85%E0%A6%A8%E0%A6%A1%E0%A6%BF%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%A1-%E0%A6%B8%E0%A7%81%E0%A6%AA%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%AA-%E0%A6%86%E0%A6%A8%E0%A7%81%E0%A6%A8">অনডিমান্ড সুপার অ্যাপ আনুন</a></td>
                </tr>
                <tr>
                  <td>01 July, 2020</td>
                  <td><img src="https://digibanglatech.news/wp-content/uploads/2021/09/logo-digi-high.png" alt="digi" width={75} height={45} /></td>
                  <td><a href="https://digibanglatech.news/apps-main/30521/">অবমুক্ত হলো অনডিমান্ড সুপার অ্যাপ ‘আনুন’ </a></td>
                </tr>
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://cdn.risingbd.com/media/common/risingbd-logo-small-sm.png" alt="rising" width={75} height={45} /></td>
                  <td><a href="https://www.risingbd.com/scienceand-technology-news/359510">অনডিমান্ড সুপার অ্যাপ ‘আনুন’ উদ্বোধন </a></td>
                </tr>
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://projuktisaradin.com/wp-content/uploads/2017/04/cropped-logo-1.jpg" alt="projukti" width={75} height={45} /></td>
                  <td><a href="https://projuktisaradin.com/%E0%A6%85%E0%A6%A8%E0%A6%A1%E0%A6%BF%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%A1-%E0%A6%B8%E0%A7%81%E0%A6%AA%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%AA-%E0%A6%86/">অনডিমান্ড সুপার অ্যাপ ‘আনুন’ অবমুক্ত হলো</a></td>
                </tr>
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://www.obiroto.com/logo.png" alt="obiroto" width={75} height={45} /></td>
                  <td><a href="https://www.obiroto.com/news/%E0%A6%AE%E0%A7%82%E0%A6%B2%E0%A6%AA%E0%A6%BE%E0%A6%A4%E0%A6%BE/23940/%E0%A6%85%E0%A6%A8%E0%A6%A1%E0%A6%BF%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%A1-%E0%A6%B8%E0%A7%81%E0%A6%AA%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%AA--%E2%80%98%E0%A6%86%E0%A6%A8%E0%A7%81%E0%A6%A8%E2%80%99%E0%A6%89%E0%A6%A8%E0%A7%8D%E0%A6%AE%E0%A7%8B%E0%A6%9A%E0%A6%A8,-%E0%A6%AA%E0%A6%BE%E0%A6%93%E0%A7%9F%E0%A6%BE-%E0%A6%AF%E0%A6%BE%E0%A6%9A%E0%A7%8D%E0%A6%9B%E0%A7%87-%E0%A7%A7%E0%A7%A9%E0%A6%9F%E0%A6%BF-%E0%A6%B8%E0%A7%87%E0%A6%AC%E0%A6%BE">অনডিমান্ড সুপার অ্যাপ- ‘আনুন’উন্মোচন, পাওয়া যাচ্ছে ১৩টি সেবা</a></td>
                </tr>
                <tr>
                  <td>01 July, 2020</td>
                  <td><img src="https://digibanglatech.news/wp-content/uploads/2021/09/logo-digi-high.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://digibanglatech.news//everyday-video/30530/"> ডিজিবাংলা : দিনের খবর : বুধবার</a></td>
                </tr>
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://www.bbarta24.net/templates/bbarta/images/main-logo.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="http://www.bbarta24.net/science-and-technology/123444">৬ তরুণের স্বপ্ন অনডিমান্ড সুপার অ্যাপ ‘আনুন’</a></td>
                </tr>
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://techvoice24.com/wp-content/uploads/2018/09/te-1.png"  alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://www.techvoice24.com/news/%e0%a6%85%e0%a6%a8%e0%a6%a1%e0%a6%bf%e0%a6%ae%e0%a6%be%e0%a6%a8%e0%a7%8d%e0%a6%a1-%e0%a6%b8%e0%a7%81%e0%a6%aa%e0%a6%be%e0%a6%b0-%e0%a6%85%e0%a7%8d%e0%a6%af%e0%a6%be%e0%a6%aa-%e0%a6%86/13006/">অনডিমান্ড সুপার অ্যাপ ‘আনুন’ এর যাত্রা শুরু</a></td>
                </tr>
               {/*  <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://itsohor.com/wp-content/uploads/2018/10/cropped-site_id_512-2.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://itsohor.com/news/%e0%a6%ae%e0%a6%ab%e0%a6%b8%e0%a7%8d%e0%a6%ac%e0%a6%b2-%e0%a6%97%e0%a7%8d%e0%a6%b0%e0%a6%be%e0%a6%ae%e0%a7%87%e0%a6%b0-%e0%a6%9c%e0%a6%a8%e0%a7%8d%e0%a6%af-%e0%a6%85%e0%a6%a8%e0%a6%a1%e0%a6%bf-18284/">মফস্বল-গ্রামের জন্য অনডিমান্ড সুপার অ্যাপ</a></td>
                </tr>*/}
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://techvoice24.com/wp-content/uploads/2018/09/te-1.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://www.techvoice24.com/news/%e0%a7%a7%e0%a7%a9%e0%a6%9f%e0%a6%bf-%e0%a6%b8%e0%a7%87%e0%a6%ac%e0%a6%be-%e0%a6%a8%e0%a6%bf%e0%a7%9f%e0%a7%87-%e0%a6%af%e0%a6%be%e0%a6%a4%e0%a7%8d%e0%a6%b0%e0%a6%be-%e0%a6%b6%e0%a7%81%e0%a6%b0/13003/">১৩টি সেবা নিয়ে যাত্রা শুরু করলো অনডিমান্ড অ্যাপ ‘আনুন’ </a></td>
                </tr><tr>
                  <td>30 June, 2020</td>
                  <td><img src="https://digibanglatech.news/wp-content/uploads/2021/09/logo-digi-high.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://digibanglatech.news//career/30423/">মফস্বল-গ্রামের জন্য অনডিমান্ড সুপার অ্যাপ আনছে ৬ তরুণ</a></td>
                </tr>
                <tr>
                  <td>30 June, 2020</td>
                  <td><img src="https://digibanglatech.news/wp-content/uploads/2021/09/logo-digi-high.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://digibanglatech.news//everyday-video/30452/">ডিজিবাংলা : দিনের খবর : মঙ্গলবার </a></td>
                </tr>
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://prottashitoalo.com/wp-content/uploads/2020/01/logo-final.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://prottashitoalo.com/%E0%A7%AC-%E0%A6%A4%E0%A6%B0%E0%A7%81%E0%A6%A3%E0%A7%87%E0%A6%B0-%E0%A6%89%E0%A6%A6%E0%A7%8D%E0%A6%AF%E0%A7%8B%E0%A6%97%E0%A7%87-%E0%A6%85%E0%A6%A8%E0%A6%A1%E0%A6%BF%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%A1-%E0%A6%B8%E0%A7%81%E0%A6%AA%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%AA-%E0%A6%86%E0%A6%A8%E0%A7%81%E0%A6%A8-%E0%A6%89%E0%A6%A6%E0%A7%8D%E0%A6%AC%E0%A7%8B%E0%A6%A7%E0%A6%A8/">৬ তরুণের উদ্যোগে অনডিমান্ড সুপার অ্যাপ ‘আনুন’ উদ্বোধন </a></td>
                </tr>
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://mangotv.co/wp-content/uploads/2019/02/MangoTV-New-logo-1.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://mangotv.co/2020/07/02/%e0%a6%9b%e0%a7%9f-%e0%a6%a4%e0%a6%b0%e0%a7%81%e0%a6%a3%e0%a7%87%e0%a6%b0-%e0%a6%a4%e0%a7%88%e0%a6%b0%e0%a6%bf-%e0%a6%b8%e0%a7%81%e0%a6%aa%e0%a6%be%e0%a6%b0-%e0%a6%85%e0%a7%8d%e0%a6%af%e0%a6%be/">ছয় তরুণের তৈরি সুপার অ্যাপ-‘আনুন’ উন্মোচন
                    </a></td>
                </tr>
                <tr>
                  <td>04 July, 2020</td>
                  <td><img src="http://awaaz.com.bd/wp-content/uploads/2021/06/awaaz-logo-.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="http://awaaz.com.bd/archives/2994">অনডিমান্ড সুপার অ্যাপ-‘আনুন’ সেবা মিলবে ১৩টি </a></td>
                </tr>
                <tr>
                  <td>04 July, 2020</td>
                  <td><img src="https://saradin.news/wp-content/uploads/2019/11/sara_logo_final.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="http://saradin.news/archives/33406">তরুণের স্বপ্নের অনডিমান্ড সুপার অ্যাপ ‘আনুন’ উন্মোচন </a></td>
                </tr>
              {/*   <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://theworldnews.net/ui/images/main_logo.svg" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="https://theworldnews.net/bd-news/anddimaandd-supaar-ayaap-aanun-udbodhn">অনডিমান্ড সুপার অ্যাপ ‘আনুন’ উদ্বোধন </a></td>
                </tr>*/}
                <tr>
                  <td>02 July, 2020</td>
                  <td><img src="https://banglarrobi.com/wp-content/uploads/2021/04/Logo_banglarrobi.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="http://banglarrobi.com/2020/07/02/%E0%A6%86%E0%A6%A8%E0%A7%81%E0%A6%A8%E0%A6%85%E0%A6%A8%E0%A6%A1%E0%A6%BF%E0%A6%AE%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%A1-%E0%A6%B8%E0%A7%81%E0%A6%AA%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A7%8D%E0%A6%AF/">আনুন’অনডিমান্ড সুপার অ্যাপ উদ্বোধন</a></td>
                </tr>
                <tr>
                  <td>16 July, 2020</td>
                  <td><img src="https://www.bbarta24.net/templates/bbarta/images/main-logo.png" alt="jugantor" width={75} height={45} /></td>
                  <td><a href="http://www.bbarta24.net/apon-aloy/124545">ছয় তরুণের স্বপ্নযাত্রা </a></td>
                </tr>
              </tbody></table>            </div>
        </div>
      </div>
    </div>
  </section>

            </div>

        )
    }


}

export default Media