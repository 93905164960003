import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
class Investors extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Investor Relations | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Investors" />
                    <meta name="title" content="Investor Relations | Anun"></meta>
                    <meta name="description" content="Investor Relations of Anun" />
                    <meta name="keywords" content="anun investors, investors relations anun" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    
                    <meta property="og:url" content="https://www.anun.xyz/Investors" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Investor Relations | Anun" />
                    <meta property="og:description" content="Investor Relations of Anun" />
                    <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="hhttp://www.anun.xyz/Investors"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Investor Relations | Anun" />
                    <meta name="twitter:description" content="Investor Relations of Anun" />
                    <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
                </Helmet>
                
                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>Investor Relations
</h2>
            
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <section className="ptb100 pt60">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="job-details-info">
          <center> 
              <h2>Announcement of the results of the first quarter of 2023</h2>
              <p>Anun plans to release its first-quarter financial statements through an online briefing at the end of April. </p> 
          <Link to="/assets/ir-latest.pdf" className="btn btn-outline-success"> Download Now </Link> </center>
        </div>
      </div>
    </div>
  </div></section>

            </div>

        )
    }


}

export default Investors