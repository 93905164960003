import React from 'react'
import { Helmet } from 'react-helmet'
class Guideline extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>User Manual | Anun</title>
                    <link rel="canonical" href="https://www.anun.xyz/Guideline" />
                    <meta name="title" content="User Manual | Anun"></meta>
                    <meta name="description" content="Welcome to Anun Shopping User Manual. Find the answers to your questions about purchasing, shipping, checking order status, returns, and more." />
                    <meta name="keywords" content="anun user manual, anun guidelines" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    <meta property="og:url" content="https://www.anun.xyz/Guideline" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="User Manual | Anun" />
                    <meta property="og:description" content="Welcome to Anun Shopping User Manual. Find the answers to your questions about purchasing, shipping, checking order status, returns, and more." />
                    <meta property="og:image" content="https://www.anun.xyz/assets/images/logo.jpg" />

                    <meta property="twitter:url" content="https://www.anun.xyz/Guideline"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="User Manual | Anun" />
                    <meta name="twitter:description" content="Welcome to Anun Shopping User Manual. Find the answers to your questions about purchasing, shipping, checking order status, returns, and more." />
                    <meta name="twitter:image" content="https://www.anun.xyz/assets/images/logo.jpg" />
                    
                </Helmet>


                <section className="page-title pt115"> 
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="section-title alt-left">
            <h2>User Manual</h2>
          </div> 
        </div>
      </div>
    </div> 
  </section>
  <section className="ptb100 pt60">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="job-details-info">
            
            <p align="justify">Welcome to Anun, an online platform where sellers and buyers can directly connect with each other. We are dedicated to promoting our sellers and allowing buyers to engage directly with them. Please note that Anun does not manufacture any products, nor are we responsible for any product sold on our platform. All goods sold on Anun are the responsibility of the individual sellers, according to their agreements with us.</p>
            <p align="justify">Please be aware that unless a product specifically states that it comes with a guarantee, Anun does not provide any guarantees for products. We encourage our customers to thoroughly research sellers and their products before making any purchases. Anun operates as a virtual shop and does not store any products. Therefore, we cannot verify the originality of products unless they are from our strategic partners.</p>
            <p align="justify">Customers are provided information regarding the sellers and are responsible for checking the originality of the said products. Being an online platform for sellers, as a virtual shop, Anun does not store any products and cannot check the originality of the products, and cannot guarantee it unless it is from their strategic partners.  </p>
            <p align="justify">If customers face difficulties with their purchases, we will ensure that products are returned within three days of receiving the product. Anun always has the best interests of its users in mind. </p>
            <p align="justify">Anun is an online platform with thousands of registered sellers, and we operate as a virtual shop. We urge our sellers to be transparent about whether any product is a duplicate or replica. Failure to do so will result in penalization for the specific store, and the seller will be held liable for any complaints regarding replica products. This could range from financial penalties to dismissal from the Anun platform.</p>
            
          </div>
        </div>
      </div>
    </div>
  </section>













            </div>

        )
    }


}

export default Guideline